import { useProduct, useCatalogProduct } from '@speedshop/sdk-api';
import {
  getCatalogProduct,
  queryCatalogProductList,
  addToCart,
  queryUACatalogProductAndFiltersList,
  getCatalogFilters,
  queryUACatalogProductListCache,
  queryWCMCatalogProductListCache
} from '@/graphql/plp.gql';
import {
  queryUAProductRelationDetail,
  createSpeedInStockNotification,
  uaQueryCouponActivityListByProduct,
  uaSendCouponToUsers,
  queryCategoryByCodes,
  subscriptionMessage,
  getSubscriptionMessage
} from '@/graphql/pdp.gql';
import { SaleAttributeCodeMapData } from '@/plugins/enmus';

// 重置PLP item 数据
// 重置PLP item 数据
const getAttributeValue = (attribute = [], code) => {
  const item = attribute.find(item => {
    return item.attrCode === code;
  });
  return item && item.attrValue && item && item.attrValue[0];
};
export const resetPLPCardData = item => {
  const { productList = [], channelSale, ...others } = item;
  const productColorList = productList.map(product => {
    const { attributes = [] } = product;
    // 商品列表商品颜色色块
    const spColor = attributes.find(item => item.attrCode === 'PDP_COLOR_DESC');
    return spColor && spColor.attrValue && spColor.attrValue.length && spColor.attrValue[0];
  });
  const tagList = productList.map(product => {
    return product.tagList || [];
  });
  const mainTagList = [...new Set(tagList.flat())];
  const spuColor = [...new Set(productColorList.filter(item => item))];
  // 神策数据
  const newProductList = productList.map(product => {
    const productData = product;
    const attributes = productData.attributes || [];
    const categoryValue =
      productData.categories?.length && productData.categories?.[0]?.attributeValues;
    const categoryPath = categoryValue?.length && categoryValue?.[0]?.attrValue?.[0].valueName;

    // 活动
    const { productCornerLabel, productActivities = [] } = productData;
    const activityAttribute = productCornerLabel
      ? [productCornerLabel.text]
      : productActivities.map(i => i.activityLabel).filter(i => i);
    // 性别
    const genderAttr = attributes.find(item => item.attrCode === 'PDP_SEX');
    // 分类
    const categoryPathList = (categoryPath && categoryPath.split('>')) || [];
    const trackData = {
      activity_attribute: activityAttribute,
      gender: genderAttr?.attrValue[0]?.valueName,
      primary_category: categoryPathList[0],
      secondary_category: categoryPathList[1],
      third_category: categoryPathList[2],
      spu_id: productData.productNo,
      spu_name: productData.productName,
      sales_price: productData.salePrice,
      list_price: productData.productPrice,
      destination_url: `/p${productData.productNo}.html`,
      is_discount: productData.productPrice > productData.salePrice
    };
    // 八字卖点
    const salePoint = getAttributeValue(attributes, 'PDP_SELLING_POINT');
    const shortName = getAttributeValue(attributes, 'PDP_SHORT_NAME');
    return {
      ...product,
      productActivities: (product.productActivities || []).filter(a => a.opsType !== '107'),
      trackData,
      totalStock: product.channelSale && product.channelSale.qty,
      salePoint: salePoint && salePoint.valueName,
      shortName: shortName && shortName.valueName
    };
  });

  return {
    ...others,
    productList: newProductList,
    ...newProductList[0],
    spuColor,
    mainTagList
  };
};
export const resetPLPCardTilingData = product => {
  const { channelSale, ...others } = product;

  const { attributes = [] } = product;
  // 商品列表商品颜色色块
  const spColor = attributes.find(item => item.attrCode === 'PDP_COLOR_DESC');
  const color = spColor && spColor.attrValue && spColor.attrValue.length && spColor.attrValue[0];
  const productColorList = [color];
  const tagList = product.tagList || [];
  const mainTagList = [...new Set(tagList.flat())];
  const spuColor = [...new Set(productColorList.filter(item => item))];
  // 神策数据
  const productData = product;
  const categoryValue =
    productData.categories?.length && productData.categories[0]?.attributeValues;
  const categoryPath = categoryValue?.length && categoryValue[0].attrValue?.[0].valueName;

  // 活动
  const { productCornerLabel, productActivities = [] } = productData;
  const activityAttribute = productCornerLabel
    ? [productCornerLabel.text]
    : productActivities.map(i => i.activityLabel).filter(i => i);
  // 性别
  const genderAttr = attributes.find(item => item.attrCode === 'PDP_SEX');
  // 分类
  const categoryPathList = (categoryPath && categoryPath.split('>')) || [];
  const trackData = {
    activity_attribute: activityAttribute,
    gender: genderAttr?.attrValue[0]?.valueName,
    primary_category: categoryPathList[0],
    secondary_category: categoryPathList[1],
    third_category: categoryPathList[2],
    spu_id: productData.productNo,
    spu_name: productData.productName,
    sales_price: productData.salePrice,
    list_price: productData.productPrice,
    destination_url: `/p${productData.productNo}.html`,
    is_discount: productData.productPrice > productData.salePrice
  };
  // 八字卖点
  const salePoint = getAttributeValue(attributes, 'PDP_SELLING_POINT');
  const shortName = getAttributeValue(attributes, 'PDP_SHORT_NAME');
  product = {
    ...product,
    productActivities: (product.productActivities || []).filter(a => a.opsType !== '107'),
    trackData,
    totalStock: product.channelSale && product.channelSale.qty,
    salePoint: salePoint && salePoint.valueName,
    shortName: shortName && shortName.valueName
  };

  return {
    ...product,
    ...others,
    spuColor,
    mainTagList
  };
};
// pdp
export const getProduct = params => {
  const pdp = useProduct({
    ...params,
    initActiveSkuData: false,
    withRecommend: false,
    disabledSoldOut: true
  });
  pdp.getProductInfo = async params => {
    const variables = params || {
      productNo: pdp.productCode,
      businessRequest: {
        activityCode: pdp.activityCode,
        statusAll: pdp.statusAll
      }
    };
    const data = await pdp.gqlClient.query(getCatalogProduct, variables);
    const {
      data: { dataInfo }
    } = data;
    const { productList = [], saleAttributes: mainSaleAttributes = [], ...others } = dataInfo;

    // const saleAttributeList = saleAttributes.filter(item => {
    //   return item.attrCode !== SaleAttributeCodeMapData.COLOR_CODE;
    // });

    const newProductList = productList.map(item => {
      return {
        ...item,
        mediaLink: item.mediaLink || item.mobileMediaLink || '',
        mobileMediaLink: item.mobileMediaLink || item.mediaLink || '',
        productActivities: (item.productActivities || []).filter(i => i.opsType !== '107')
      };
    });
    const activeProduct = newProductList.find(item => item.productNo === pdp.productCode);
    const activeColor =
      activeProduct &&
      activeProduct.skuList &&
      activeProduct.skuList[0] &&
      activeProduct.skuList[0].attributeValues &&
      activeProduct.skuList[0].attributeValues.find(
        item => item.attrCode === SaleAttributeCodeMapData.COLOR_CODE
      );
    const { saleAttributes = [], skuList = [] } = activeProduct || {};

    const activePDP_COLORCODE =
      (activeProduct && activeProduct.attributes.find(a => a.attrCode === 'PDP_COLORCODE')) || {};
    const activeColorCode =
      activePDP_COLORCODE.attrValue &&
      activePDP_COLORCODE.attrValue.length &&
      activePDP_COLORCODE.attrValue[0].valueCode;

    const newSkuList = skuList.map(item => {
      const {
        attributeValues = [],
        channelSale: { qty } = {},
        skuCampaign: { maxPerUser } = {},
        availableQty
      } = item;

      const newAttributeValues = attributeValues.map(i => {
        if (i.attrCode === SaleAttributeCodeMapData.COLOR_CODE) {
          const { attrValue = [] } = i;
          const valueName = attrValue.length ? attrValue[0].valueName : '';
          return {
            ...i,
            attrValue: [
              {
                valueCode: activeColorCode,
                valueName
              }
            ]
          };
        } else {
          return i;
        }
      });
      const list = [qty, maxPerUser, availableQty, 5].filter(i => typeof i === 'number');
      const min = Math.min.apply(null, list);
      return {
        ...item,
        realStock: min,
        attributeValues: newAttributeValues,
        mediaLink: item.mediaLink || activeProduct.mediaLink,
        mobileMediaLink: item.mobileMediaLink || activeProduct.mobileMediaLink
      };
    });
    const saleAttributeList = ((activeProduct && activeProduct.saleAttributes) || []).map(item => {
      if (item.attrCode === SaleAttributeCodeMapData.COLOR_CODE) {
        const attrValue = newProductList.map(spu => {
          const { skuList = [], attributes } = spu;
          const PDP_COLORCODE = attributes.find(a => a.attrCode === 'PDP_COLORCODE') || {};
          const attr =
            (skuList.length &&
              skuList[0]?.attributeValues?.find(
                i => i.attrCode === SaleAttributeCodeMapData.COLOR_CODE
              )?.attrValue?.[0]) ||
            {};
          const valueImage = (spu.mediaLink || spu.mobileMediaLink || '')
            .replace(/,http/g, '[http')
            .split('[');
          return {
            ...attr,
            valueCode: (PDP_COLORCODE.attrValue && PDP_COLORCODE.attrValue[0].valueCode) || '',
            valueImage: valueImage.length && valueImage[0],
            productCode: spu.productCode,
            productNo: spu.productNo
          };
        });
        return {
          ...item,
          attrValue
        };
      } else {
        return item;
      }
    });
    const isStock = ((activeProduct && activeProduct.skuList) || []).some(i => {
      const { channelSale: { qty } = {} } = i;
      return qty && qty > 0;
    });

    if (productList.length) {
      return {
        ...others,
        productList: newProductList,
        ...activeProduct,
        isStock,
        skuList: newSkuList,
        saleAttributes: saleAttributeList,
        activeColor: activeColor && activeColor.attrValue[0].valueCode
      };
    } else {
      return {
        ...others
      };
    }
  };
  pdp.addToCart = async params => {
    const {
      distributionType = '11',
      skuCode = pdp.activeSkuData?.skuCode,
      quantity = 1,
      orgCode = pdp.originalDataInfo.orgCode,
      productCode = pdp.originalDataInfo.productCode,
      activityCode = pdp.activeSkuData?.activityCode,
      activityType = pdp.activeSkuData?.activityType,
      ...otherOptions
    } = params;
    const variables = {
      input: {
        skuCode,
        quantity,
        orgCode,
        productCode,
        distributionType,
        activityCode,
        activityType,
        ...otherOptions
      }
    };
    await pdp.gqlClient.mutate(addToCart, variables);
    return await pdp.getMiniCartNum();
  };
  pdp.getRecommendProductList = async params => {
    const {
      data: {
        data: { relationProductList, mediaLinkList, mobileMediaLinkList }
      }
    } = await pdp.gqlClient.mutate(queryUAProductRelationDetail, {
      input: params || {
        productCode: pdp.originalDataInfo.productCode
      }
    });
    const linkSplit = str => (str || '').replace(/,http/g, '[http').split('[');
    const getFirst = list => (Array.isArray(list) ? list[0] : '');

    const list = Array.isArray(relationProductList)
      ? relationProductList.map(({ mobileMediaLink = '', mediaLink = '', ...others }) => {
          return {
            ...others,
            mediaLinkList: linkSplit(mediaLink || mobileMediaLink || ''),
            mediaLink,
            mobileMediaLink
          };
        })
      : [];
    return {
      list,
      mediaLink: getFirst(mediaLinkList),
      mobileMediaLink: getFirst(mobileMediaLinkList)
    };
  };
  pdp.stockSubsribe = async input => {
    await this.gqlClient.query(createSpeedInStockNotification, {
      input
    });
  };
  pdp.queryCoupons = async function queryCoupons(condition) {
    const {
      data: { data }
    } = await this.gqlClient.query(uaQueryCouponActivityListByProduct, {
      condition
    });

    return data;
  };
  pdp.pickupCoupon = async function pickupCoupon(input) {
    await this.gqlClient.query(uaSendCouponToUsers, {
      input
    });
  };
  pdp.queryCategories = async function queryCategories(codes) {
    const {
      data: { list }
    } = await this.gqlClient.query(queryCategoryByCodes, {
      input: {
        codeList: codes
      }
    });
    return list;
  };
  pdp.subscriptionMessage = async function ({ businessCode, code }) {
    await this.gqlClient.query(subscriptionMessage, {
      input: { businessCode, code }
    });
  };
  pdp.getSubscriptionMessage = async function (params) {
    const {
      data: { list }
    } = await this.gqlClient.query(getSubscriptionMessage, params);
    return list;
  };
  return pdp;
};
// ------------  聚合 -------------
// 购物车 查询商品详情
export const getProductWithSku = () => {
  const pdp = useProduct({ initActiveSkuData: false });
  pdp.getProductInfo = async params => {
    const variables = params || {
      productCode: pdp.productCode,
      businessRequest: {
        activityCode: pdp.activityCode,
        statusAll: pdp.statusAll,
        inventoryFlag: 1
      }
    };
    const data = await pdp.gqlClient.query(getCatalogProduct, variables);
    const {
      data: { dataInfo }
    } = data;
    const { productList = [], saleAttributes: mainSaleAttributes = [], ...others } = dataInfo || {};

    const skuList = productList
      .map(item => {
        const { skuList = [], attributes } = item;
        const activePDP_COLORCODE = attributes.find(a => a.attrCode === 'PDP_COLORCODE') || {};
        const activeColorCode =
          activePDP_COLORCODE.attrValue &&
          activePDP_COLORCODE.attrValue.length &&
          activePDP_COLORCODE.attrValue[0].valueCode;
        const list = item.skuList.map(sku => {
          const { attributeValues = [] } = sku;
          const newAttributeValues = attributeValues.map(i => {
            if (i.attrCode === SaleAttributeCodeMapData.COLOR_CODE) {
              const { attrValue = [] } = i;
              const valueName = attrValue.length ? attrValue[0].valueName : '';
              return {
                ...i,
                attrValue: [
                  {
                    valueCode: activeColorCode,
                    valueName
                  }
                ]
              };
            } else {
              return i;
            }
          });
          return {
            ...sku,
            mediaLink: sku.mediaLink || item.mediaLink,
            mobileMediaLink: sku.mobileMediaLink || item.mobileMediaLink,
            productCode: item.productCode,
            attributeValues: newAttributeValues
          };
        });
        return list;
      })
      .flat();

    const saleAttributeList = mainSaleAttributes.map(item => {
      if (item.attrCode === SaleAttributeCodeMapData.COLOR_CODE) {
        const attrValue = productList.map(spu => {
          const { skuList = [], attributes } = spu;
          const PDP_COLORCODE = attributes.find(a => a.attrCode === 'PDP_COLORCODE') || {};
          const attr =
            (skuList.length &&
              skuList[0].attributeValues.find(
                i => i.attrCode === SaleAttributeCodeMapData.COLOR_CODE
              ).attrValue[0]) ||
            {};
          const valueImage = (spu.mediaLink || spu.mobileMediaLink || '')
            .replace(/,http/g, '[http')
            .split('[');
          return {
            ...attr,
            valueImage: valueImage.length && valueImage[0],
            valueCode: (PDP_COLORCODE.attrValue && PDP_COLORCODE.attrValue[0].valueCode) || '',
            productCode: spu.productCode
          };
        });
        return {
          ...item,
          attrValue
        };
      } else {
        return item;
      }
    });

    if (productList.length) {
      return {
        ...others,
        ...productList[0],
        productList,
        skuList,
        saleAttributes: saleAttributeList
      };
    } else {
      return {
        ...others
      };
    }
  };
  return pdp;
};

export const CatalogProduct = params => {
  const plp = useCatalogProduct(params);
  plp.isCache = params && params.isCache;
  plp.wcmProductReqList = params && params.wcmProductReqList;
  plp.getCatalogProductList = async () => {
    plp.checkQuery();
    const { pageSize, pageNum } = plp;

    plp.loading = true;

    const request = plp.getCondition();
    const { conditionList = [], ...otherConditions } = request;
    console.log('conditionList', conditionList);
    /// / 写死tag
    const newConditionList = conditionList.map(item => {
      const newItem = item.map(({ key, ...others }) => {
        if (key === 'tags.tagCode') {
          return {
            key: 'tags.displayName.name',
            type: 3,
            values: others.values
          };
        } else if (key === 'categories.categoryCode') {
          //
          return {
            key: 'categories.categoryName',
            type: 3,
            values: others.values
          };
        } else {
          return {
            key,
            ...others
          };
        }
      });
      return newItem;
    });
    const variables = {
      pageInput: {
        pageSize: 24,
        pageNum
      },
      // request
      request: {
        conditionList: newConditionList,
        ...otherConditions
      }
    };
    plp.wcmProductReqList && (variables.wcmProductReqList = plp.wcmProductReqList);
    const {
      data: {
        data: { pageInfo, dataList }
      }
    } = await plp.gqlClient
      .query(plp.isCache ? queryUACatalogProductListCache : queryCatalogProductList, variables)
      .then(data => data)
      .finally(() => {
        plp.loading = false;
      });
    plp.pageInfo = pageInfo;
    const list = dataList.map(item => {
      return { data: resetPLPCardData(item.data) };
    });
    return {
      pageInfo,
      dataList: list,
      hasNextPage: pageInfo.hasNextPage
    };
  };
  plp.getFilterConditionData = async () => {
    const request = plp.getCondition();
    // getCatalogFilters
    const {
      data: { data }
    } = await plp.gqlClient.query(getCatalogFilters, { request, filterBlackList: true });

    const filterData = plp.formatDataFilterData(data);
    plp.filterData = filterData;
    return filterData;
  };
  return plp;
};
export const CatalogWcmProduct = params => {
  const plp = useCatalogProduct(params);
  // plp.isCache = params && params.isCache;
  // plp.wcmProductReqList = params && params.wcmProductReqList;
  plp.getCatalogProductList = async () => {
    plp.checkQuery();
    const { pageSize, pageNum } = plp;

    plp.loading = true;

    const request = plp.getCondition();
    const { conditionList = [], ...otherConditions } = request;
    console.log('conditionList', conditionList);
    /// / 写死tag
    const newConditionList = conditionList.map(item => {
      const newItem = item.map(({ key, ...others }) => {
        if (key === 'tags.tagCode') {
          return {
            key: 'tags.displayName.name',
            type: 3,
            values: others.values
          };
        } else if (key === 'categories.categoryCode') {
          //
          return {
            key: 'categories.categoryName',
            type: 3,
            values: others.values
          };
        } else {
          return {
            key,
            ...others
          };
        }
      });
      return newItem;
    });
    const variables = {
      pageInput: {
        pageSize: 24,
        pageNum
      },
      // request
      request: {
        conditionList: newConditionList,
        ...otherConditions
      }
    };
    plp.wcmProductReqList && (variables.wcmProductReqList = plp.wcmProductReqList);
    const {
      data: {
        data: { pageInfo = {}, dataList = [] }
      }
    } = await plp.gqlClient
      .query(queryWCMCatalogProductListCache, variables)
      .then(data => data)
      .finally(() => {
        plp.loading = false;
      });
    plp.pageInfo = pageInfo;
    const list = dataList.map(item => {
      return { data: resetPLPCardTilingData(item.data) };
    });
    return {
      pageInfo: pageInfo || {},
      dataList: list,
      hasNextPage: pageInfo.hasNextPage
    };
  };
  plp.getFilterConditionData = async () => {
    const request = plp.getCondition();

    // getCatalogFilters
    const {
      data: { data }
    } = await plp.gqlClient.query(getCatalogFilters, { request, filterBlackList: true });

    const filterData = plp.formatDataFilterData(data);
    plp.filterData = filterData;
    return filterData;
  };
  return plp;
};
export const UseSearch = params => {
  const plp = useCatalogProduct(params);
  plp.getCatalogProductList = async () => {
    plp.checkQuery();
    plp.loading = true;

    const request = plp.getCondition();
    const variables = {
      pageInput: {
        pageSize: 4,
        pageNum: 1
      },
      request
    };
    const {
      data: {
        data: { dataList, navigationList, cateogryList }
      }
    } = await plp.gqlClient
      .query(queryUACatalogProductAndFiltersList, variables)
      .then(data => data)
      .finally(() => {
        plp.loading = false;
      });
    const list = dataList.map(item => {
      const {
        data: { productList = [], ...others }
      } = item;
      const productColorList = productList.map(product => {
        const { attributes = [] } = product;
        const spColor = attributes.find(item => item.attrCode === 'PDP_COLOR_GROUP');
        return spColor && spColor.attrValue && spColor.attrValue.length && spColor.attrValue[0];
      });
      const tagList = productList.map(product => {
        return product.tagList || [];
      });
      const mainTagList = [...new Set(tagList.flat())];
      const spuColor = [...new Set(productColorList.filter(item => item))];
      const activeSpu = (productList.length && productList[0]) || {};
      const newProductList = productList.map(i => {
        return {
          ...i
        };
      });
      return {
        data: {
          ...others,
          productList: newProductList,
          ...activeSpu,
          spuColor,
          mainTagList
        }
      };
    });
    return {
      dataList: list,
      navigationList,
      cateogryList
    };
  };

  plp.search = async keyword => {
    plp.conditionKeyword = keyword;

    const { dataList, navigationList, cateogryList = [] } = await plp.refresh();
    // function findAllLastChildren(tree) {
    //   const lastChildren = [];

    //   function findLast(node, firstParentName) {
    //     if (!node.children || node.children.length === 0) {
    //       lastChildren.push({ ...node, firstParentName });
    //     } else {
    //       for (const child of node.children) {
    //         findLast(child, firstParentName);
    //       }
    //     }
    //   }

    //   for (const root of tree) {
    //     findLast(root, root.categoryName);
    //   }

    //   return lastChildren;
    // }

    // const allLastChildren = findAllLastChildren(cateogryList);

    // const newCategoryList = allLastChildren.map(item => {
    //   return {
    //     name: item.firstParentName + item.categoryName,
    //     url: `/list/${item.extCode}`
    //   };
    // });

    // const newNavigationList = navigationList.map(item => {
    //   // let parentNavigation = item.parentNavigation || {};
    //   // while (parentNavigation) {
    //   //   if (parentNavigation.parentNavigation) {
    //   //     parentNavigation = parentNavigation.parentNavigation;
    //   //   } else {
    //   //     break;
    //   //   }
    //   // }
    //   const { extendParams = '{}' } = item || {};
    //   const extendParamsData = JSON.parse(extendParams || '{}');
    //   const { color } = extendParamsData;
    //   return {
    //     name: item.navigationName,
    //     url: item.navigationUrl || `/list/c${item.extCode}`,
    //     color
    //   };
    // });
    // const newNavigationListAndCategoryList = [...newNavigationList];
    // const newNavigationListAndCategoryListMap = new Map();
    // newNavigationListAndCategoryList.forEach(item => {
    //   const data = newNavigationListAndCategoryListMap.get(item.name);
    //   if (data) {
    //     //
    //   } else {
    //     newNavigationListAndCategoryListMap.set(item.name, item);
    //   }
    // });
    if (navigationList.length) {
      navigationList.forEach(item => {
        if (item.navigationUAS.length) {
          item.navigationUAS.forEach(i => {
            i.url = `/list/c${i.extCode}`;
            i.name = i.categoryName;
          });
          if (item.navigationUAS[item.navigationUAS.length - 1]) {
            item.url = `/list/c${item.navigationUAS[item.navigationUAS.length - 1].extCode}`;
          } else {
            item.url = '';
          }
        }
      });
    }

    return { dataList, category: navigationList };
  };
  return plp;
};

export const getDisplayTagName = (list = []) => {
  // const hot = (list || []).find(item => JSON.stringify(item).includes('热卖'));
  // const news = (list || []).find(item => JSON.stringify(item).includes('新品'));
  // const firstTag = list.length && list[0];
  // return hot ? '热卖' : news ? '新品' : firstTag.displayName || '';
  if (list.length) {
    return list[0].displayName;
  } else {
    return '';
  }
};
