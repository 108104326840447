import Vue from 'vue';
import sensors from 'sa-sdk-javascript';
import SessionEvent from 'sa-sdk-javascript/dist/web/plugin/session-event/index.es6.js';
import pageleave from 'sa-sdk-javascript/dist/web/plugin/pageleave/index.es6.js';
import cookie from 'js-cookie';

import type { TrackHelpPluginOptions } from './track-help/index';
import TrackHelp from './track-help/index';
import { setPublicUserData } from '@/utils/track';
// import * as Sensors((Events from './sensors-events';

sensors.use(pageleave, {
  custom_props: {
    prop1: 'value1'
  },
  heartbeat_interval_time: 5,
  max_duration: 5 * 24 * 60 * 60,
  isCollectUrl(_url) {
    // url 为要采集页面浏览时长的页面地址。
    return true; // 采集
    // return false; // 不采集
  }
});

sensors.use(SessionEvent, {});

sensors.init({
  server_url:
    window.location.host === 'www.underarmour.cn'
      ? 'https://underarmour.datasink.sensorsdata.cn/sa?project=production&token=59ec5a2f43b5a6bb'
      : 'https://underarmour.datasink.sensorsdata.cn/sa?project=default&token=59ec5a2f43b5a6bb',
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  send_type: 'beacon',
  show_log: false,
  heatmap: {
    // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'default',
    // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'default'
  }
});
// 登录之后将公共属性数据存起来

// 公共属性
// app_name	应用名称
// platform_type	用户平台
// first_visit_url	首次访问页面url

// belong_page	当前页面名称
// current_url	当前页面url

// source_first_nav	来源一级导航栏
// source_second_nav	来源二级导航栏
// source_third_nav	来源三级导航栏
// source_navigation	上一级导航栏
// operation_name	运营位名称（首页）
// operation_id	运营位id（首页）
// operation_rank	运营位顺序（首页）
// source_module	来源版区（首页）
// key_word_list	搜索词

// 初始化静态公共属性
// 获取动态公共属性
setPublicUserData();
const presetProperties = JSON.parse(localStorage.getItem('sensorsPublicData') || '{}');
sensors.registerPage({
  ...presetProperties,
  app_name: 'UA官方商城',
  platform_type: 'PC端',
  current_url: location.href,
  belong_page: document.title
});

// 用于采集 $pageview 事件
sensors.quick('autoTrack');

const service = {
  install(Vue) {
    Vue.prototype.$sensors = sensors;
  }
};

Vue.use(service);

export const sensorsIns = sensors;

export default ({ app }) => {
  if (process.client) {
    /**
     * 指令v-IO-track, v-expose-track, v-click-track, v-hover-track
     * 方法一: 传入参数对象，如: :v-expose-track:exposeView="{ ... }" 将直接触发trackHandle
     * 方法二: 传入方法 :v-expose-track="myTrackHandle", 即执行自定义的方法
     * 方法三: 传入指令支持的options对象, 如:
     * :v-expose-track="{
     *   eventName: exposeView,
     *   data: { ... },
     *   trackHandle: myTrackHandle,
     *   beforeTrack： mybeforeTrack,
     *   afterTrack: myafterTrack
     * }"
     * 支持修饰符.once，作用是事件只触发一次即解绑。
     *
     * TrackHelp在开启usePreviousCache后，router必传。且注册全局对象$trackHelp。
     * $trackHelp.setPageData(obj): 暂存数据
     * $trackHelp.getPreviousPageData(): 获取前置数据
     *
     * $previousPage, v-IO-track将被废弃，目前依旧可以用。
     *
     */
    Vue.use(TrackHelp, {
      usePreviousCache: true,
      router: app.router,
      observes: {
        first_visit_url: {
          duration: 30 * 60 * 1000,
          resetHandle: () => location.href
        },
        key_word_list: {
          duration: 30 * 60 * 1000,
          resetHandle: () => []
        }
      },
      inactiveHandle(key, data) {
        console.log('user-active-listener: registerPage', key, data);
        sensors.registerPage({
          [key]: data
        });
      },
      trackHandle(trackObj, utils) {
        // 运营位曝光和点击事件过滤没有有运营位名称和模块名称
        if (['operationExpose', 'operationClick'].includes(trackObj.eventName)) {
          const data = trackObj.data;
          const { operation_name: operationName, module_name: moduleName } = data;
          if (!moduleName) {
            console.warn('operationExpose or operationClick event must have moduleName');
          } else {
            // eslint-disable-next-line camelcase
            const { operation_name, operation_id, operation_rank, module_rank, module_name } = data;

            sensors.track(trackObj.eventName, {
              ...trackObj.data
            });
            if (trackObj.eventName === 'operationClick') {
              // eslint-disable-next-line camelcase
              console.log('set Page data');
              //
              const presetProperties = JSON.parse(
                localStorage.getItem('sensorsPublicData') || '{}'
              );

              const cookieData = JSON.parse(cookie.get('sensors_publick_properties') || '{}');
              const payload = {
                ...cookieData,
                ...presetProperties,
                operation_name,
                operation_id,
                operation_rank,
                // eslint-disable-next-line camelcase
                source_module: [module_name],
                module_name,
                module_rank
              };
              // 从公共属性中去除动态属性，不需要存储，以实际上报为准
              delete payload.module_name;
              delete payload.module_rank;
              delete payload.operation_id;
              delete payload.operation_name;
              delete payload.operation_rank;
              // eslint-disable-next-line camelcase
              localStorage.setItem('sensorsPublicData', JSON.stringify(payload));
              cookie.set('sensors_publick_properties', JSON.stringify(payload), {
                path: '/',
                ...(process.env.NODE_ENV === 'production'
                  ? {
                      sameSite: 'None',
                      domain: '.' + location.host.split('.').slice(-2).join('.'),
                      secure: true
                    }
                  : {})
              });
            }
          }
        } else {
          sensors.track(trackObj.eventName, trackObj.data);
        }
      },
      afterRouteEnterHandle(currentPageData, to, from) {
        return {
          source_url: from.name ? `${location.origin}${to.fullPath}` : '',
          page_source: from.name ? to.meta.title || to.name || document.title : '',
          ...currentPageData
        };
      }
    } as TrackHelpPluginOptions);
  }
  app.router.beforeResolve((to, from, next) => {
    console.log('sensors: router.beforeResolve', to, from, history.state, app.router.history);
    // 更新页面相关的动态属性
    sensors.registerPage({
      current_url: `${location.origin}${to.fullPath}`,
      belong_page: to.meta.title || to.name || document.title
      // page_source: document.referrer
      // source_url: ''
    });
    next();
  });
};
