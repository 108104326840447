import ClipboardJS from 'clipboard';

// 复杂对象合并
export const MergeRecursive = (obj1, obj2) => {
  const arr = Object.keys(obj2);
  let index = -1;
  while (++index < arr.length) {
    const p = arr[index];
    try {
      if (obj2[p].constructor === Object) {
        obj1[p] = MergeRecursive(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch (e) {
      obj1[p] = obj2[p];
    }
  }
  return obj1;
};

export const debounce = (fn, wait = 50, immediate = false) => {
  let timer = null;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);

    // timer 为空表示首次触发
    if (immediate && !timer) {
      fn.apply(context, args);
    }

    timer = setTimeout(() => {
      fn.apply(context, args);
    }, wait);
  };
};

export const formatDate = (dateString = '') => {
  const pattern = /(\d{4})(\d{2})(\d{2})/;
  const formatedDate = dateString.replace(pattern, '$1-$2-$3');
  return formatedDate;
};

// 多个图片的时候用 , http分隔
export const getImgLink = linkListString => {
  const processItem = str => str.replace(/.*(?=\/\/)/g, '');
  if (typeof linkListString !== 'string') return '';
  const list = linkListString.split(',http') || [];
  return list.map(v => {
    return processItem(v);
  });
};

export const copyText = (selector, successCB) => {
  const target = document.querySelector(selector);

  if (!target) return;

  target.setAttribute('data-clipboard-target', selector);

  const clipboard = new ClipboardJS(selector, {
    text: el => el.textContent.trim()
  });
  const clear = () => clipboard.destroy();

  clipboard.on('success', function (e) {
    e.clearSelection();
    successCB();
    clear();
  });

  clipboard.on('error', function (e) {
    console.error(e);
    clear();
  });

  target.click();
};

export const goPDP = (data, newTab = true, app) => {
  if (!(data.productNo || data.productCode)) return '';

  const routerJumpData = {
    name: 'pdp',
    params: { code: data.productNo || data.productCode || '' }
  };

  const { href } = app.$router.resolve({
    ...routerJumpData
  });

  const previousData = {
    source_url: app.$route.path,
    page_source: app.$route.meta.title || app.$route.name
  };
  const path = (href || '/').replace(window.location.origin, '');
  app.$trackHelp.cachePageData(path, previousData);

  if (newTab) {
    return href;
  } else {
    return routerJumpData;
  }
};

export const groupBy = (arr, key) => {
  return arr
    .map(item => item[key])
    .reduce((acc, curr, i) => {
      acc[curr] = acc[curr] || [];
      acc[curr].push(arr[i]);
      return acc;
    }, {});
};

export const arrayToMap = (array, key) => {
  const map = new Map();

  // 遍历数组中的每个元素
  array.forEach(function (element) {
    // 使用指定的key来获取对应的值
    const mapKey = element[key];
    // 将整个元素作为值添加到Map对象中
    map.set(mapKey, { ...element });
  });

  return map;
};

//  为何要 mock window.open https://developer.mozilla.org/en-US/docs/Web/API/Window/open
export const mockWindowOpen = (
  url,
  target = '_blank',
  rel = 'noopener',
  referrerPolicy = 'origin'
) => {
  // rel = "noopener" 表示浏览器不要将当前网站的 window 变量附加到新打开的恶意网站。
  const aTag = document.createElement('a');

  //  SEO相关：nofollow和external
  // 浏览器安全相关：noopener和noreferrer
  aTag.rel = rel;
  aTag.referrerPolicy = referrerPolicy;
  aTag.target = target;
  aTag.href = url;

  aTag.click();
  aTag && aTag.remove();
};

export const isValidUrl = string => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export const getSearchParams = function (search) {
  const paramPart = search.substr(1).split('&');
  return paramPart.reduce(function (res, item) {
    const parts = item.split('=');
    res[parts[0]] = parts[1];
    return res;
  }, {});
};

export function throttle(fn, delay) {
  let now, lastExec, timer, context, args, timeLeft;
  const execute = function () {
    fn.apply(context, args);
    lastExec = now;
  };
  return function () {
    context = this;
    args = arguments;
    now = Date.now();
    timeLeft = delay - (now - lastExec);
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    if (lastExec) {
      const timeLeft = delay - (now - lastExec);
      if (timeLeft < 0) {
        execute();
      } else {
        timer = setTimeout(() => {
          execute();
        }, timeLeft);
      }
    } else {
      execute();
    }
  };
}

// 判断是否是 json 字符串   attribute 有时返回异常 为了容错
export const isJSONString = str => {
  if (typeof str === 'string') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  return false;
};

// mobile tab 间距最小20 如果屏幕可分配大于20则平分
export const initMobileTab = pageEle => {
  if (!pageEle) return;

  const ele = '.el-tabs__item';
  const list = document.querySelectorAll(ele) || [];
  let totalWidth = -80;
  const totalClientWidth = document.querySelector(pageEle).offsetWidth;

  if (totalClientWidth > 500) return;

  let paddingStr = '';

  list.forEach(v => {
    totalWidth = totalWidth + v.offsetWidth;
  });

  if (totalClientWidth < totalWidth || (totalClientWidth - totalWidth) / list.length < 20) {
    paddingStr = '20px';
  }

  list.forEach((v, i) => {
    if (i == 0) {
      list[i].style.padding = paddingStr
        ? `0 ${paddingStr} 0 0`
        : `0 calc((100% - ${totalWidth}px)/4) 0 0`;
    } else if (i == list.length - 1) {
      list[i].style.padding = paddingStr
        ? `0 0 0 ${paddingStr}`
        : `0 0 0 calc((100% - ${totalWidth}px)/4)`;
    } else {
      list[i].style.padding = paddingStr ? `0 ${paddingStr}` : `0 calc((100% - ${totalWidth}px)/4)`;
    }
  });
};

/**
 * @description: 规范化JSON.parse调用
 * @param {any} data
 * @return {JSONObject | undefined}
 */
export const jsonParse = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return undefined;
  }
};

export const getExtensionFromUrl = url => {
  const regex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
  const match = url?.match(regex);
  return match?.[0];
};

export const isVideoType = url => {
  const extType = getExtensionFromUrl(url);
  return ['.mp4', '.avi', '.mov', '.wmv', '.MP4', '.AVI', '.MOV', '.WMV'].includes(extType);
};

export const isImageType = url => {
  const extType = getExtensionFromUrl(url);
  return ['.jpg', '.png', '.JPG', '.PNG', '.gif', '.GIF', '.jpeg', '.JPEG'].includes(extType);
};
