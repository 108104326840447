export const isAndroid = (userAgent?: string) => {
  const ua = userAgent || navigator.userAgent;
  return ua.includes('Android') || ua.includes('Adr'); // android终端
};

export const isIOS = (userAgent?: string) => {
  const ua = userAgent || navigator.userAgent;
  return !!ua?.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
};

export const isMobile = (userAgent?: string) => isAndroid(userAgent) || isIOS(userAgent);
