export default function (count = 10, type = 'animation-frame', duration = 10) {
  return {
    data() {
      return {
        displayPriority: 0,
        deferedCount: count
      };
    },

    mounted() {
      this.runDisplayPriority();
    },

    methods: {
      runDisplayPriority() {
        const step = this.timerConstruct(() => {
          this.displayPriority++;
          if (this.displayPriority < this.deferedCount) {
            step();
          }
        });
        step();
      },
      timerConstruct(cb) {
        return type === 'timer' ? () => setTimeout(cb, duration) : () => requestAnimationFrame(cb);
      },

      defer(priority) {
        return this.displayPriority >= priority;
      },
      updateDeferCount(n) {
        this.deferedCount = n;
      }
    }
  };
}
